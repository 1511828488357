<template>
  <div class="home">
    <form-wizard @on-complete="onComplete"
      :title="''"
      :subtitle="''"
      back-button-text="zurück"
      next-button-text="weiter"
      finish-button-text="Absenden"
      color="#CC944C"
      @on-validate="handleValidation"
        @on-error="handleErrorMessage"
    >
        <wizard-step 
            slot-scope="props"
            slot="step"
            :tab="props.tab"
            :transition="props.transition"
            :index="props.index">
        </wizard-step>

        <tab-content title="Informationen"
                      icon="ti-user"
                      class="mx-2 mx-md-5"
                      style="height: 320px"
                      :before-change="validateAsyncStep1"
                      >

        
            <b-form-group
              class="text-left"
              id="input-order-id"
              label="Bestell-ID:"
              label-for="order-id"
              description="Gebe Deine Digistore24 Bestell-ID ein."
            >
              <b-form-input
                :state="!form.orderId ? false : true"
                id="order-id"
                v-model="form.orderId"
                type="text"
                placeholder="Bestell-ID eingeben..."
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              class="text-left"
              id="email-1"
              label="E-Mail:"
              label-for="email"
              description="Gebe Deine E-Mail-Adresse ein."
            >
              <b-form-input
                :state="!form.email ? false : true"
                id="email"
                v-model="form.email"
                type="text"
                placeholder="E-Mail eingeben..."
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              class="text-left"
              id="phonenumber-group"
              label="Telefonnummer:"
              label-for="phone"
              description="Gebe Deine Telefonnummer ein."
            >
              <b-form-input
                :state="!form.phone ? false : true"
                id="phone"
                v-model="form.phone"
                type="tel"
                placeholder="Telefonnummer eingeben..."
                required
              ></b-form-input>
            </b-form-group>

                      
        </tab-content>

        <tab-content title="Datum wählen"
                      icon="ti-calendar"
                      class="mx-2 mx-md-5"
                      style="height: 320px"
                      :before-change="validateAsyncStep2"
                      >
          <!-- <v-date-picker v-model="date" mode="dateTime" is24hr :min-date="minDate">
            <template v-slot="{ inputValue, inputEvents }">
              <b-form-group
              class="text-left"
              id="date-group"
              label="Datum:"
              label-for="date"
              description="Wann kann ich dich am Besten erreichen?"
            >
              <b-form-input
                :state="!inputValue ? false : true"
                id="date"
                v-model="form.date"
                :value="inputValue"
                v-on="inputEvents"
                required
              ></b-form-input>
            </b-form-group>

            </template>
          </v-date-picker> -->
          <v-date-picker v-model="form.date" mode="dateTime" is24hr :min-date="minDate"/>
        </tab-content>

        <tab-content title="Abschließen"
                      icon="ti-check"
                      class="mx-2 mx-md-5"
                      style="height: 320px"
                      :before-change="validateAsyncStep3"
                      >
          <div v-if="!messageSent">
            <b-form-group
                class="text-left"
                id="message-group"
                label="Nachricht:"
                label-for="message"
                description="Muss ich noch etwas wissen?"
              >

              <b-form-textarea
                id="message"
                v-model="form.text"
                placeholder="Nachricht eingeben..."
                rows="6"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>
            <b-form-group id="input-group-5">
              <b-form-checkbox-group
                :state="!form.checked > 0 ? false : true"
                v-model="form.checked"
                id="checkboxes-4"
                required
              >
                <b-form-checkbox value="me"><a href="https://media-lf.de/datenschutz" target="_blank">Datenschutz</a> gelesen und akzeptiert</b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>
          </div>
          <div v-if="messageSent">
            <h2 class="text-center text-success my-1">Nachricht wurde gesendet.</h2>
            <h3 class="text-center">Ich melde mich umgehend bei Dir!</h3>
          </div>
        </tab-content>

    </form-wizard>
    

  </div>
</template>

<script>
// @ is an alias to /src
import VDatePicker from 'v-calendar/lib/components/date-picker.umd'
import axiosAuth from '@/shared/configs/axios-auth'

export default {
  name: 'Home',
  
  components: {
    VDatePicker
  },
  computed: {
    errorMessage() {
      if (!this.date) return 'Date is required.';
      return '';
    },
  },

  data: () => {
    return {
      messageSent: false,
      minDate: null,
      date: null,
      loadingWizard: false,
         errorMsg: null,
         count: 0,
      form: {},
    }
  },

  mounted(){
    this.minDate = new Date()
  },

  methods: {
    onComplete: function(){

      if(!this.messageSent){
        try {
            axiosAuth.post('/aftersale', this.form)
            .then(
                () => {
                    this.messageSent = true
                }
              )
            .catch(
                error => {
                  this.messageSent = false
                  alert('Error: ', error);
                    console.log(error.response)
                }
            ) 
            
          } catch (error) {
            console.log(error)
          }
      }
      
           
    },
    setLoading: function(value) {
        this.loadingWizard = value
    },
    handleValidation: function(isValid, tabIndex){
        console.log('Tab: '+tabIndex+ ' valid: '+isValid)
    },
    handleErrorMessage: function(errorMsg){
      console.log(errorMsg)
      this.errorMsg = errorMsg
    },

    validateAsyncStep1:function() {
          return new Promise((resolve, reject) => {
              if(!this.form.orderId || !this.form.email || !this.form.phone){
                reject('Es wurden nicht alle Felder ausgefüllt!')
              }else{
                resolve(true)
              }
          })
    },

    validateAsyncStep2:function() {
          return new Promise((resolve, reject) => {
              if(!this.form.date){
                reject('Es wurden nicht alle Felder ausgefüllt!')
              }else{
                resolve(true)
              }
          })
    },

    validateAsyncStep3:function() {
          return new Promise((resolve, reject) => {
              if(!this.form.checked){
                reject('Es wurden nicht alle Felder ausgefüllt!')
              }else{
                resolve(true)
              }
          })
    },
  }
}
</script>

<style scoped>
.wizard-btn {

    outline: none !important;

}

.text-muted {

    outline: none !important;

}
</style>